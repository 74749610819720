/*=============================================
=            service tab            =
=============================================*/

.service-tab {
  &__link-wrapper {
    @media #{$large-mobile} {
      margin-bottom: 40px;
    }
    &.nav-tabs {
      border-bottom: 0;
    }
    .nav-link,
    .react-tabs__tab {
      position: relative;
      padding: 30px 20px;
      line-height: 1;
      background-color: $white;
      box-shadow: 0 1px 15.36px 0.64px rgba(0, 0, 0, 0.15);
      margin-bottom: 15px;
      font-size: 24px;
      font-weight: 700;
      border: 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      @media #{$desktop-device, $tablet-device} {
        font-size: 20px;
      }
      @media #{$large-mobile} {
        font-size: 18px;
        padding: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      i {
        vertical-align: middle;
        font-size: 40px;
        margin-right: 10px;
      }

      &:after {
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin-left: -15px;
        z-index: -1;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 194, 70, 0);
        border-left-color: $theme-color--default;
        border-width: 15px;
        visibility: hidden;
        opacity: 0;
        transition: $transition--cubic;
        transition-duration: 0.2s;

        @media #{$large-mobile} {
          display: none;
        }
      }

      &:hover,
      &.active,
      &.react-tabs__tab--selected {
        background-color: $theme-color--default;
        color: $white;
        border-radius: 0;
        &:after {
          visibility: visible;
          opacity: 1;
          margin-left: 0;
        }
      }

      button {
        background: none;
        border: none;
      }
    }
  }

  &__single-content-wrapper {
    padding: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    //background-image: url('/assets/img/backgrounds/service-tab1.jpg');
    @media #{$extra-small-mobile} {
      padding: 40px 15px;
    }
  }

  &__single-content {
    color: $white;
    padding: 40px;
    position: relative;
    z-index: 1;
    width: 580px;
    max-width: 100%;

    @media #{$extra-small-mobile} {
      padding: 40px 15px;
    }
    .see-more-link {
      color: $white;
      &:hover {
        color: $theme-color--default;
      }
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: 0.7;
      content: "";
      z-index: -1;
    }
  }

  &__title {
    color: $white;
    font-size: 30px;
    margin-bottom: 20px;

    @media #{$extra-small-mobile} {
      font-size: 25px;
    }
  }

  &__text {
    margin-bottom: 30px;
    width: 415px;
    max-width: 100%;
    font-size: 16px;
    line-height: 1.8;
  }
}

.service-tab-wrapper {
  .react-tabs__tab-panel {
    opacity: 0;
    transition: $transition--cubic;
    margin-left: 40px;
    &--selected {
      opacity: 1;
      height: 100%;

      @media #{$large-mobile} {
        margin-left: 0;
      }
    }
  }
}

/*=====  End of service tab  ======*/
